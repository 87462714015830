$mobile-width-max: 599px;
$tablet-width-min: 600px;
$desktop-width-min: 800px;
$primary: #fed63c;

body {
  margin: 0;

  #admin-content {
    margin: auto;
    padding-left: 5%;
    padding-right: 5%;
  }

  #content {
    @media (max-width: $mobile-width-max) {
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 20px;
    }

    margin: 0 auto;
    max-width: 800px;
  }

  &.default-layout {
    #content {
      @media (min-width: $tablet-width-min) {
        width: 430px;
      }
    }
  }
}

.center-self-x {
  margin-left: auto;
  margin-right: auto;
}

.center-text-x {
  text-align: center;
}

.disabled {
  color: gray;
}

.display-flex {
  display: flex;
}

.display-flex-lg {
  display: flex;
  @media (max-width: $mobile-width-max) {
    display: unset;
  }
}

.display-none {
  display: none !important;
}

.sm-padding-y {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.height-fit-button {
  height: fit-content;
  min-height: 36px;
}

.reorderable-container {
  padding-left: 0;
  padding-right: 0;

  .draggable-box {
    border-radius: 4px;
    cursor: ns-resize;
    display: flex;
    height: auto;
    line-height: 48px;
    list-style-type: none;
    padding-bottom: 3px;
    padding-top: 3px;

    &:hover {
      background-color: #ccc;
    }

    .orderable-label {
      margin-left: 6px;
      padding-top: 6px;
      padding-left: 6px;
      padding-right: 6px;
      background-color: $primary;
      border-radius: 5px;
    }

    .orderable-status {
      flex-basis: 0;
      margin-left: 6px;
      margin-right: 6px;
      min-width: 25px;
      padding-top: 6px;
      width: 25px;
    }
  }
}

.help-title {
  text-decoration: underline;
  font-weight: bold;
}

.help-resources {
  .mdc-list-divider {
    list-style: none;
  }
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin: 0 16px;

  p {
    margin: 0;
  }
}

.login-form-container {
  min-width: 325px;
}

.vimeo-video {
  display: block;
  margin: 0 auto;

  @media (max-width: $mobile-width-max) {
    height: 240px;
    width: 320px;
  }
  @media (min-width: $tablet-width-min) {
    height: 360px;
    width: 480px;
  }
  @media (min-width: $desktop-width-min) {
    height: 480px;
    width: 640px;
  }
}

#get-tested-game {
  #question-wrapper {
    margin-left: 16px;
    margin-right: 16px;
  }

  .in-game-header {
    background-color: #fed73b;
    border-radius: 12px;
    color: #2c6354;
    padding: 40px;
  }

  .mdc-card {
    background-color: #cddfd4;
    border-radius: 12px;
    height: fit-content;
    width: 300px;
    @media (max-width: $mobile-width-max) {
      width: 100%;
    }

    h2 {
      text-align: center;
    }
  }

  .question-panel {
    border: 1px solid lightgray;
    border-radius: 12px;
    padding: 12px;

    .mdc-list-item {
      background-color: #d9d9d9;
      border-radius: 12px;
      color: #7e7e7e;
      margin-top: 4px;
      padding: 6px 12px;

      &.mdc-list-item--disabled {
        background-color: #cddfd4;
        color: inherit;
      }

      &.mdc-list-item--selected {
        background-color: #fcb69e;
        color: inherit;
      }
    }
  }
}

#porn-reality {
  .slider-labels {
    margin-bottom: 2%;
  }
}
