$form-field-width: 430px;
$mobile-form-field-width: 100%;
$mobile-width-max: 599px;
$tablet-width-min: 600px;

@use 'sass:color';

/*
 * Material specific imports and configuration
 */
@use '@material/theme' with (
  $primary: #fed63c,
  $secondary: #fd7532,
  $on-primary: #2c6354,
  $on-secondary: #000000,
  $error: #b00020
);

@use '@material/animation/animation';
@use '@material/banner';
@use '@material/banner/styles';
@use '@material/button/mdc-button';
@use '@material/button';
@use '@material/card';
@use '@material/checkbox/mdc-checkbox';
@use '@material/data-table/data-table';
@use '@material/dialog/mdc-dialog';
@use '@material/drawer/mdc-drawer';
@use '@material/elevation';
@use '@material/floating-label/mdc-floating-label';
@use '@material/form-field/_index.scss' as form-field;
@use '@material/form-field/mdc-form-field';
@use '@material/icon-button/mdc-icon-button';
@use '@material/image-list/mdc-image-list';
@use '@material/image-list';
@use '@material/layout-grid/mdc-layout-grid';
@use '@material/line-ripple/mdc-line-ripple';
@use '@material/list/mdc-list';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';
@use '@material/notched-outline/mdc-notched-outline';
@use '@material/radio/styles' as radioStyles;
@use '@material/select/mdc-select';
@use '@material/select/styles' as selectStyles;
@use '@material/slider/styles' as sliderStyles;
@use '@material/snackbar/mdc-snackbar';
@use '@material/tab-bar/mdc-tab-bar';
@use '@material/tab-indicator/mdc-tab-indicator';
@use '@material/tab-scroller/mdc-tab-scroller';
@use '@material/tab/mdc-tab';
@use '@material/textfield/_index.scss' as textfield;
@use '@material/textfield/icon';
@use '@material/textfield/mdc-text-field';
@use '@material/tooltip/styles' as tooltipStyles;
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/typography';
@use '@material/typography/mdc-typography';

@include card.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
@include form-field.core-styles;
@include icon.icon-core-styles;

/*
 * Rails specific imports
 */

@import 'trix/dist/trix';

/*
 * Typography for general elements
 */

html {
  --mdc-typography-subtitle1-font-size: 1.375rem;
  --mdc-typography-subtitle2-font-size: 1.25rem;
  --mdc-typography-headline6-font-size: 1rem;
  --mdc-typography-subtitle2-font-weight: 400;
}

h1 {
  @include typography.typography(headline3);
}

h2 {
  @include typography.typography(headline5);
}

h3 {
  @include typography.typography(headline6);
}

label,
li,
p,
span,
div {
  @include typography.typography(body1);
}

small {
  @include typography.typography(body2);
}

.mdc-banner {
  .mdc-banner__fixed {
    @media (max-width: $mobile-width-max) {
      height: 72px;
    }
  }

  .mdc-banner__text {
    color: theme.$error;
  }

  .mdc-banner__text.info {
    color: theme.$on-primary;
  }
}

.mdc-card.game {
  background-color: color.adjust(theme.$primary, $alpha: -0.92);
  margin-bottom: 8px;
  margin-right: 8px;
}

.mdc-list.question-panel {
  @media (max-width: $mobile-width-max) {
    display: none;
  }
  min-width: 200px;

  .mdc-list-item--disabled {
    background-color: #ddd;
    opacity: 0.7;
  }
}

.mdc-top-app-bar__section#brand {
  padding-left: 0;

  a {
    @media (min-width: $tablet-width-min) {
      padding-top: 5px;
    }
    text-decoration: none;

    img {
      @media (max-width: $mobile-width-max) {
        height: 62px;
      }
      @media (min-width: $tablet-width-min) {
        height: 65px;
      }
      display: inline-block;
    }

    .mdc-top-app-bar__title {
      @include typography.typography(headline5);
      color: theme.$on-primary;
      display: inline-block;
      @media (max-width: $mobile-width-max) {
        display: none;
      }
    }
  }
}

.mdc-top-app-bar__section[role='toolbar'] {
  .mdc-button {
    @include typography.typography(headline6);
  }
}

.commentary {
  @include typography.typography(subtitle1);
}

.feedback {
  @include elevation.elevation(3);
  @include typography.typography(subtitle2);
  border: 1px solid black;
  padding: 20px;
}

.rating-container {
  margin: 0 40% 20px;

  @media (max-width: $mobile-width-max) {
    margin: 0 10% 10px;
  }
  @media (min-width: $tablet-width-min) {
    margin: 0 30% 20px;
  }
}

.instructions {
  @include elevation.elevation(3);
  @include theme.property(border-color, primary);
  @include typography.typography(subtitle2);
  border-style: solid;
  border-width: 5px;
  padding: 20px;
}

.instructions p {
  @include typography.typography(subtitle2);
}

form {
  .mdc-button {
    display: flex;

    @media (max-width: $mobile-width-max) {
      width: $mobile-form-field-width;
    }
  }

  .mdc-form-field {
    display: flex;
  }

  .mdc-text-field {
    @include textfield.caret-color(on-primary);
    @include textfield.label-color(on-primary);
    @media (max-width: $mobile-width-max) {
      width: $mobile-form-field-width;
    }
    @media (min-width: $tablet-width-min) {
      width: $form-field-width;
    }

    &.fullwidth {
      width: 100%;
    }

    .mdc-text-field__input {
      font-size: 1em;
    }
    .mdc-floating-label {
      font-size: 1em;
    }
  }

  label {
    display: flex;

    &.mdc-text-field {
      display: flex;
      width: $form-field-width;
    }
  }

  .mdc-select {
    #select-label {
      font-size: 1em;
    }
    .mdc-list {
      font-size: 0.75em;
      .mdc-list-item__primary-text {
        font-size: 1em;
      }
    }
  }
}

.danger-button {
  @include button.filled-accessible(theme.$error);
}

/* tables */
.mdc-data-table__header-cell-wrapper {
  font-size: 0.75em;
}
.mdc-data-table__cell {
  max-width: 175px;
}
.admin-table-cell {
  max-width: unset;
}

/* image list */
.homepage-image-list {
  @include image-list.standard-columns(3);
  @media (max-width: $mobile-width-max) {
    @include image-list.standard-columns(1);
  }

  .activity-progress {
    bottom: 48px;
    color: green;
    height: calc(100% - 48px);
    position: absolute;
    text-align: center;
    width: 100%;

    .fa-2xl {
      font-size: 5em;
      position: relative;
      top: 50%;
    }
  }

  .with-progress {
    opacity: 75%;
  }
}

.mdc-image-list__supporting {
  padding: 7px !important;
}

.vignette-image {
  width: 66%;
  margin: auto;
}

.video-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
  margin-top: 5%;
}

.mdc-tooltip__content {
  @media (max-width: $mobile-width-max) {
    font-size: 16px !important;
  }
}

.fa-circle-info {
  font-size: 25px;
  padding: 7px;
}

.danger-text {
  color: theme.$error;
}

.mdc-dialog__title {
  line-height: normal;
  color: black;
}

#get-tested-dialog-content {
  color: black;
}
